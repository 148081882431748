import React, { useEffect } from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import { inject, observer } from 'mobx-react';
import './about.css';
import md5 from '../images/md5.png';
import md9 from '../images/md9.png';
import Button from '../components/Button';

const AboutPage = (observer(({ store }) => {
  useEffect(() => {
    store.closeMenu();
  }, []);

  return (
    <Layout>
      <Seo title='O nama' description='Informacije o kompaniji Piperski D.O.O., kao i način rada izgradnje Faradejevih kaveza odnosno MRI kabina i drugih proizvoda za MRI i RF zaštitu.' />
      <article>
        <div className="page-title page-title-bg-one">
          <h1>O nama</h1>
        </div>
        <section className="about-wrapper container">
          <div className="about-text-wrapper">
            <div className="about-title">
              <h3>O nama</h3>
              <div className="title-dash"></div>
            </div>
            <div className="about-text">
              <p>Piperski D.O.O. je kompanija osnovana 2004. sa sedištem u Beogradu.
                Specijalizovani smo za RF zaštitu magnetnih rezonanci, što uključuje projektovanje,
                proizvodnju svih zasebnih komponenti i kompletnu konstrukciju i instalaciju Faradejevih kaveza (RF Kabina).

                Svako izvođenje radova kao i proizvodnja zasebnih delova se radi po tehničkim specifikacijama proizvođača MRI.
                Do sada smo realizovali preko 80 saradnji u 30 zemalja sveta sa 5 različitih MRI proizvođača.
              </p>
            </div>
          </div>
          <div className="about-image-wrapper">
            <img loading='lazy' alt='MRI tehnički crtež kabine' src={md9} />
          </div>
        </section>

        <div className="separator-5" />

        <section className="about-wrapper hide-mobile container">
          <div className="about-image-wrapper">
            <img loading='lazy' alt='MRI tehnički crtež polja' style={{ maxWidth: '305px' }} src={md5} />
          </div>
          <div className="about-text-wrapper">
            <div className="about-title">
              <h3>Proces</h3>
              <div className="title-dash"></div>
            </div>
            <div className="about-text">
              <p>
                Način izrade kome pristupamo podrazumeva adaptabilnu instalaciju Faradejevog kaveza,
                odnosno prilagođavanje čak i netipičnim prostorima.
                Nakon procene i projektovanja materijali od ključne važnosti se naručuju
                direktno od proizvođača sirovina (bakar, drvo, ramovi).
                Sve pojedinačne komponente (vrata, prozor, filteri, talasovodi)  se izrađuju od početka u našim radionicama. Garancija koju pružamo na realizovane poslove je 2 godine.
              </p>
            </div>
          </div>
        </section>

        <section className="about-wrapper show-mobile container">
          <div className="about-text-wrapper">
            <div className="about-title">
              <h3>Proces</h3>
              <div className="title-dash"></div>
            </div>
            <div className="about-text">
              <p>
                Način izrade kome pristupamo podrazumeva adaptabilnu instalaciju Faradejevog kaveza,
                odnosno prilagođavanje čak i netipičnim prostorima.
                Nakon procene i projektovanja materijali od ključne važnosti se naručuju
                direktno od proizvođača sirovina (bakar, drvo, ramovi).
                Sve pojedinačne komponente (vrata, prozor, filteri, talasovodi)  se izrađuju od početka u našim radionicama,
                kako bismo bili sigurni u njihov kvalitet. Garancija koju pružamo na realizovane poslove je 2 godine.
              </p>
            </div>
          </div>
          <div className="about-image-wrapper">
            <img loading='lazy' alt='MRI tehnički crtež polja' style={{ maxWidth: '305px' }} src={md5} />
          </div>
        </section>

        <div className="separator-5" />
        <div className="find-out-more">
          <div className="find-out-more-text">
            <p>Više detalja o proizvodima možete videti na stranici Proizvodi</p>
          </div>
          <Button size='medium' link='/products' text='Proizvodi' />
        </div>

        <div style={{ marginTop: '45px', marginBottom: '33px' }} className="find-out-more">
          <div className="find-out-more-text">
            <p>Kontaktirajte nas za sva ostala pitanja</p>
          </div>
          <Button size='medium' link='/contact' text='Kontakt' />
        </div>
      </article>
    </Layout>
  )
}))

export default inject('store')(AboutPage);
